<template>
	<v-row>
		<BottomNavigationContainer v-if="menu.length > 1" :menu="menu" />

		<v-col cols="12" class="d-none d-md-block">
			<ToolbarContainer :menu="menu" />
			<v-toolbar elevation="1" height="25" rounded="b">
				<Breadcrumbs />
			</v-toolbar>
		</v-col>

		<v-col cols="12" lg="12">
			<router-view />
		</v-col>
	</v-row>
</template>

<script>
import Breadcrumbs from "../Widgets/Breadcrumbs";
import ToolbarContainer from "../Widgets/ToolbarContainer";
import BottomNavigationContainer from "../Widgets/BottomNavigationContainer";
import { mapState } from "vuex";

export default {
	name: "ChamadoContainer",
	components: { Breadcrumbs, ToolbarContainer, BottomNavigationContainer },
	data: () => ({
		menu: [],
	}),
	computed: {
		...mapState(["usuario"]),
	},
	methods: {
		montarMenu() {
			let m = [];

			if (this.permissao("chamado")) {
				m.push({
					titulo: "Meus chamados",
					titulo_app: "Chamados",
					icon: "mdi-account-question-outline",
					to: "/chamado",
					tooltip: "Lista dos meus chamados",
				});
			}

			if (this.usuario.iddepartamento) {
				m.push({
					titulo: "Chamados recebidos",
					titulo_app: "Recebidos",
					icon: "mdi-message-question-outline",
					to: "/chamado/recebido",
					tooltip: "Lista de chamados recebidos",
				});
			}

			if (this.permissao("chamado")) {
				m.push({
					titulo: "Novo chamado",
					titulo_app: "Novo",
					icon: "mdi-message-plus-outline",
					to: "/chamado/novo",
					tooltip: "Tela de abertura de chamado",
				});
			}

			if (this.permissao("chamado")) {
				m.push({
					titulo: "Notificações",
					titulo_app: "Notificações",
					icon: "mdi-bell-badge-outline",
					to: "/chamado/notificacao",
					tooltip: "Lista de notificações de chamados",
				});
			}

			if (this.permissao("chamado_configuracao")) {
				m.push({
					titulo: "Configurações",
					titulo_app: "Configurações",
					icon: "mdi-cog-outline",
					to: "/chamado/configuracao",
					tooltip: "Permite atualizar configurações de chamados",
				});
			}

			if (this.permissao("chamado_dashboard")) {
				m.push({
					titulo: "Dashboard chamados",
					titulo_app: "Dashboard",
					icon: "mdi-chart-box-outline",
					to: "/chamado/dashboard",
					tooltip: "Dashboard de chamados",
				});
			}

			this.menu = m;
		},
	},
	mounted() {
		this.montarMenu();
	},
};
</script>

<style scoped>
</style>